.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.bg-tab {
    background-color: rgba(128, 128, 128, 0.1);
    padding: 2px;
    border-radius: 15px;
}

.btn-tab {
    background-color: white;
    color: grey;
    border: none;
    padding: 10px;
    border-radius: 15px;
}

.btn-tab-seleted {
    background-color: rgb(44, 174, 255);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 15px;
}

/* Error Page */
.error-page {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(114, 114, 114);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
}